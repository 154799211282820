/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        // Returns a function, that, as long as it continues to be invoked, will not
        // be triggered. The function will be called after it stops being called for
        // N milliseconds. If `immediate` is passed, trigger the function on the
        // leading edge, instead of the trailing.
        var debounce = function (func, wait, immediate) {
          var timeout;
          return function() {
            var context = this, args = arguments;
            var later = function() {
              timeout = null;
              if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
          };
        };

        var removeLocationText = function () {
          var windowWidth = $(window).width();
          if (windowWidth <= 320) {          
            if ( $('.map-filters-header').find('h3 i').hasClass('fa-angle-down') ) {
              $('.map-filters-header h3').html('Filter <i class="fa fa-angle-down"></i>');
            } else {
              $('.map-filters-header h3').html('Filter <i class="fa fa-angle-up"></i>');
            }
          } else {
            if ( $('.map-filters-header').find('h3 i').hasClass('fa-angle-down') ) {
              $('.map-filters-header h3').html('Filter Locations <i class="fa fa-angle-down"></i>');
            } else {
              $('.map-filters-header h3').html('Filter Locations <i class="fa fa-angle-up"></i>');
            }
          }
        };

        $.fn.equalizeHeight = function() {
          var maxHeight = 0, itemHeight;
     
          for (var i = 0; i < this.length; i++) {
            itemHeight = $(this[i]).height();
            if (maxHeight < itemHeight) {
                maxHeight = itemHeight;
            }
          }
     
          return this.height(maxHeight);
        };



        ////////////////
        // Mobile Nav //
        ////////////////

        $('.mobile-nav-link').click(function(e){
          e.preventDefault();
          $('.mobile-nav').addClass('active');
          $('.mobile-overlay').addClass('active');
        });
        $('.mobile-nav .btn-close').click(function(e){
          e.preventDefault();
          $('.mobile-nav').removeClass('active');
          $('.mobile-overlay').removeClass('active');
        });

        $('.mobile-nav .has-subnav > a').click(function(e){
          e.preventDefault();
         // $('.mobile-nav .has-subnav').removeClass('active');
          $(this).parent().toggleClass('active');
        });

        $('.mobile-nav .has-subnav > a').each(function(){
          $(this).next().prepend('<li><a href="'+$(this).attr('href')+'">View '+$(this).text()+'</a><li>');
        });


        $('.mobile-nav .menu-item-has-children').append('<a href="#" class="mobile-nav-dropdown">&#x25BE;</a>');
        $('.mobile-nav-dropdown').click(function(e){
          e.preventDefault();
          $(this).parent().toggleClass('active');
        });



        ////////////////////////
        // PRESS ARTICLE PAGE //
        ////////////////////////
        
        $('.press-article .container-fluid > .row > div').equalizeHeight();


        
        //$('[class^=".col-"]').equalizeHeight();
        //$('.landing-page-tiles .tile').height('').equalizeHeight().css('display','block');

        //search
        $('.btn-main-search a').click(function(e){
          e.preventDefault();
          $('.search-overlay').addClass('active');
        });

        $('.search-overlay .btn-close').click(function(e){
          $('.search-overlay').removeClass('active');
        });


        //slideshow
        $('.homepage-slideshow').slick({
          fade:true,
          arrows:false,
          autoplay:true,
          autoplaySpeed:7000,
          pauseOnHover:false,
          speed:2000
        });

        $('.slideshow').slick({
          fade:true,
          arrows:false,
          autoplay:true,
          autoplaySpeed:4000,
          pauseOnHover:false,
          speed:1000
        });


        //google maps
        //
        var infowindow = new google.maps.InfoWindow({ content:"" });
        //
        function new_map( $el ) {
  
          // var
          var $markers = $el.find('.marker');
            
          
          // var isDraggable = $(document).width() > 480 ? true : false;

          // vars
          var args = {
            zoom    : 16,
            draggable: true,
            center    : new google.maps.LatLng(0, 0),
            mapTypeId : google.maps.MapTypeId.ROAD,
            panControl: true,
            scrollwheel: false,
            styles:[{"featureType":"road","elementType":"geometry","stylers":[{"lightness":100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"geometry","stylers":[{"visibility":"on"},{"color":"#C6E2FF"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#C5E3BF"}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#D1D1B8"}]}]
          };
  
  
          // create map           
          var map = new google.maps.Map( $el[0], args);
          
          
          

          // add a markers reference
          map.markers = [];
          

          // add markers
          $markers.each(function(){
              
              add_marker( $(this), map );
            
          });
  
  
          // center map
          center_map( map );
          
          
          // return
          return map;
        }

        /*
        *  add_marker
        *
        *  This function will add a marker to the selected Google Map
        *
        *  @type  function
        *  @date  8/11/2013
        *  @since 4.3.0
        *
        *  @param $marker (jQuery element)
        *  @param map (Google Map object)
        *  @return  n/a
        */



        function add_marker( $marker, map ) {

          // var
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

          //get marker image from data-icon attribute
          if($marker.attr('data-icon') != null){
            var icon = $marker.attr('data-icon');
          }

          if($marker.attr('data-marker-type') != null){
            var category = $marker.attr('data-marker-type');
          }

          // create marker
          var marker = new google.maps.Marker({
            position  : latlng,
            map     : map,
            icon : icon,
            category : category,
          });

          if ($('.interactive-map').length) {
            marker.setVisible(false);
          } else {
            marker.setVisible(true);
          }

          // add to array
          map.markers.push( marker );

          // if marker contains HTML, add it to an infoWindow
          if( $marker.html() )
          {
           

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.close();
                infowindow.setContent($marker.html());
                infowindow.open(map,marker);
            });
          }
        }

        function displayMarkers(category){
          var i;

          if(category == 'show-all'){
            for (i = 0; i < map.markers.length; i++) {
                 map.markers[i].setVisible(true);
             }
          } else {
             for (i = 0; i < map.markers.length; i++) {
               if (map.markers[i].category === category) {
                 map.markers[i].setVisible(true);
               }
             }
           }
        }

        function hideMarkers(category){
          var i;

          if(category == 'show-all'){
            for (i = 0; i < map.markers.length; i++) {
                 map.markers[i].setVisible(false);
             }
          } else {

            for (i = 0; i < map.markers.length; i++) {
               if (map.markers[i].category === category) {
                 map.markers[i].setVisible(false);
               }
             }
           }
        }

        $('.map-filters input[type="checkbox"]').change(function(e){
          if(this.checked){
            displayMarkers($(this).attr('data-category'));
          } else {
            hideMarkers($(this).attr('data-category'));
          }
        });



        /////////////////////////////////////////////////////////////////
        // make the filter section collapsible on small screen devices //
        /////////////////////////////////////////////////////////////////
        var windowWidth = $(window).width();
        $('.map-filters-header').on('click', function(e){
          if (windowWidth < 992) {
            if ( $('.map-filters-header').find('h3 i').hasClass('fa-angle-down') ) {
              $('.map-filters-header').find('h3 i').removeClass('fa-angle-down');
              $('.map-filters-header').find('h3 i').addClass('fa-angle-up');
            } else {
              $('.map-filters-header').find('h3 i').removeClass('fa-angle-up');
              $('.map-filters-header').find('h3 i').addClass('fa-angle-down');
            }

            $('.map-filters-filters').toggleClass('mobile-active');
          } else {
            // just kill the click event
            e.preventDefault();
            e.stopPropagation();
          }
        });

        /////////////////////////////////////////////////////////////////////////////////////////
        // Remove the word "Location" from the location filter box on the Interactive Map page //
        /////////////////////////////////////////////////////////////////////////////////////////
        $(window).resize(debounce(removeLocationText, 300, false));


        /*
        *  center_map
        *
        *  This function will center the map, showing all markers attached to this map
        *
        *  @type  function
        *  @date  8/11/2013
        *  @since 4.3.0
        *
        *  @param map (Google Map object)
        *  @return  n/a
        */

        function center_map( map ) {

          // vars
          var bounds = new google.maps.LatLngBounds();

          // loop through all markers and create bounds
          $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

            bounds.extend( latlng );

          });

          // only 1 marker?
          if( map.markers.length == 1 )
          {
            // set center of map
              map.setCenter( bounds.getCenter() );
              map.setZoom( 16 );
          }
          else
          {
            // fit to bounds
            map.fitBounds( bounds );
          }

        }

        /*
        *  document ready
        *
        *  This function will render each map when the document is ready (page has loaded)
        *
        *  @type  function
        *  @date  8/11/2013
        *  @since 5.0.0
        *
        *  @param n/a
        *  @return  n/a
        */
        // global var
        var map = null;

        $(document).ready(function(){
          $('.acf-map').each(function(){

            // create map
            map = new_map( $(this) );

          });

          //set map filters panel to be draggable
          $('.map-filters').draggable({ 
            handle: ".map-filters-header",
            containment: "parent",
          });

          //show interactive map markers depending on checked categories
          $('.map-filters input[type="checkbox"]:checked').each(function(e){
            displayMarkers($(this).attr('data-category'));
          });
        });

      } // end finalize
    }, // end common
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'maps_directions' : {
      finalize: function() {

        function initMap() {
            var directionsDisplay = new google.maps.DirectionsRenderer;
            var directionsService = new google.maps.DirectionsService;
            var map = new google.maps.Map(document.getElementById('directions-map'), {
              zoom: 13,
              center: {lat: 36.075317, lng: -79.099387}
            });
            directionsDisplay.setMap(map);
            directionsDisplay.setPanel(document.getElementById('directions'));

            /*var control = document.getElementById('floating-panel');
            control.style.display = 'block';
            map.controls[google.maps.ControlPosition.TOP_CENTER].push(control);

            var onChangeHandler = function() {
              calculateAndDisplayRoute(directionsService, directionsDisplay);
            };
            document.getElementById('start').addEventListener('change', onChangeHandler);
            document.getElementById('end').addEventListener('change', onChangeHandler);
            */

            $('.btn-get-directions').click(function(e){

              console.log('clicked');
              calculateAndDisplayRoute(directionsService, directionsDisplay);

            });
          }

          function calculateAndDisplayRoute(directionsService, directionsDisplay) {
            var start = document.getElementById('starting-location').value;
            var end = document.getElementById('ending-location').value;
            directionsService.route({
              origin: start,
              destination: end,
              travelMode: 'DRIVING'
            }, function(response, status) {
              if (status === 'OK') {
                $('#right-panel').addClass('active');
                directionsDisplay.setDirections(response);

              } else {
                window.alert('Please enter a starting location.');
              }
            });
          }

          initMap();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param $el (jQuery element)
*  @return  n/a
*/

